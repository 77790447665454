<template>
  <div :class="className">
    <div
      v-if="!hideHeader"
      :class="['collection-preview__header', 'sub-header', ...headerClasses]"
    >
      <icon :size="20" class="mr-2">{{ icon }}</icon>
      <t sm semibold>{{ collection.length }} {{ title }}</t>

      <template v-if="mq['sm-']">
        <div class="spacer" />

        <ToggleButton v-if="shouldShow.smToggle" v-model="sm">
          <template v-slot:true>thumbnails</template>
          <template v-slot:false>thumbnails-small</template>
        </ToggleButton>
      </template>

      <template v-else>
        <template v-if="shouldShow.groupedToggle">
          <div class="h-splitter" />

          <div class="d-flex-r d-flex-a-c">
            <t sm semibold class="mr-2">{{ $t('g.mosaic-view') }}</t>

            <ESwitch v-model="grouped" :true-value="false" />
          </div>
        </template>

        <div class="spacer" />

        <ToggleButton v-if="shouldShow.smToggle" v-model="sm">
          <template v-slot:true>thumbnails</template>
          <template v-slot:false>thumbnails-small</template>
        </ToggleButton>
      </template>
    </div>

    <div :class="['collection-preview__body', ...bodyClasses]">
      <div v-if="grouped && groupBy" class="v-spacing-5">
        <div v-for="(group, key) in groupedCollection" :key="key">
          <slot name="label" v-bind="{ key }" />

          <div class="collection-preview__grid">
            <DocPreviewWrapper
              v-for="doc in group"
              :key="doc.id"
              :active="doc.id === currentDocId"
            >
              <DocPreview
                v-bind="docBind(doc)"
                @mouseover.native="onHover(doc)"
              />
            </DocPreviewWrapper>
          </div>
        </div>
      </div>

      <div v-else class="collection-preview__grid">
        <DocPreviewWrapper
          v-for="doc in collection"
          :key="doc.id"
          :active="doc.id === currentDocId"
        >
          <DocPreview v-bind="docBind(doc)" @mouseover.native="onHover(doc)" />
        </DocPreviewWrapper>
      </div>
    </div>
  </div>
</template>

<script>
import { WithViewport } from '@/mixins';
import mixin from './mixin';

// components
import DocPreview from '@/components/collection/DocPreview.vue';
import DocPreviewWrapper from '@/components/collection/DocPreviewWrapper.vue';
import ESwitch from '@/components/formulate/Switch.vue';
import ToggleButton from '@/components/ds/ToggleButton.vue';

export default {
  name: 'CollectionPreview',
  components: { DocPreview, DocPreviewWrapper, ESwitch, ToggleButton },
  mixins: [WithViewport, mixin],
  props: {
    currentDocId: {
      type: String,
      default: undefined,
    },

    groupBy: {
      type: Function,
      default: undefined,
    },

    headerClasses: {
      type: String,
      default: undefined,
    },
    bodyClasses: {
      type: String,
      default: undefined,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return this.getDefaultData();
  },
  computed: {
    // header
    icon() {
      if (this.type === 'projects') return 'map-marker';
      if (this.type === 'services') return 'video';

      return '';
    },
    title() {
      if (this.type === 'projects') return this.$t('g.projects');
      if (this.type === 'services') return this.$t('g.services');

      return '';
    },

    shouldShow() {
      return { groupedToggle: this.groupBy, smToggle: !this.groupBy };
    },

    // body
    groupedCollection() {
      const { groupBy } = this;
      if (!groupBy) return;

      return _.groupBy(this.collection, groupBy);
    },

    className() {
      const className = 'collection-preview';

      return [className, { [`${className}--sm`]: this.sm }];
    },
  },
  watch: {
    type: 'reset',
  },
  methods: {
    getDefaultData() {
      return { grouped: Boolean(this.groupBy), sm: false };
    },
    reset() {
      Object.assign(this.$data, this.getDefaultData());
    },

    docBind(doc) {
      const bind = { doc, type: this.type, sm: this.sm, square: this.sm };

      return Object.assign({}, bind, this.$attrs);
    },

    onHover(doc) {
      this.$emit('focus-change', doc);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
.collection-preview
  height: 100%

// START - scrollable body -----------------------------------------------------
.collection-preview
  +d-flex-c

  & > .collection-preview__header
    flex-shrink: 0

  & > .collection-preview__body
    flex: 1

    overflow-y: auto

    +no-scrollbar
// END - scrollable body -------------------------------------------------------

.collection-preview__grid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax($doc-preview-min-width, auto))
  grid-auto-rows: min-content
  gap: $spacing-2

// - sm
.collection-preview--sm
  .collection-preview__grid
    grid-template-columns: repeat(2, minmax($doc-preview-sm-min-width, auto))

// color
.collection-preview
  background-color: var(--c-background)
</style>
