<template>
  <div class="toggle-button">
    <transition name="fade-interrupted-normal">
      <!-- fix -->
      <div v-if="value">
        <EButton v-bind="buttonBind" @click="$emit('input', false)">
          <slot name="false" />
        </EButton>
      </div>

      <EButton v-else v-bind="buttonBind" @click="$emit('input', true)">
        <slot name="true" />
      </EButton>
    </transition>
  </div>
</template>

<script>
import { WithSize } from 'odd-ds';

export default {
  name: 'ToggleButton',
  mixins: [WithSize],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttonBind() {
      return { icon: true, sm: true };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$default-size: 24px

.toggle-button
  --size: #{$default-size}

  position: relative

  +size(var(--size))

  & > *
    +p-absolute-top-left(null, 0)
</style>
