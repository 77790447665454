<template>
  <div class="v-services-index">
    <CollectionPreview
      :collection="services"
      :group-by="mq['md+'] ? service => service.projectId : undefined"
      type="services"
    >
      <template v-slot:label="{ key: projectId }">
        <t lg semibold class="mb-3">
          {{ _.find(projects, { id: projectId }).name }}
        </t>
      </template>
    </CollectionPreview>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { WithViewport } from '@/mixins';

// components
import CollectionPreview from '@/components/collection/CollectionPreview.vue';

export default {
  name: 'VServicesIndex',
  components: { CollectionPreview },
  mixins: [WithViewport],
  computed: {
    ...mapGetters('collections', { services: 'getAllServices' }),
    ...mapGetters('collections', [
      'areAllProjectsFetched',
      'areAllServicesFetched',
      'getAllProjects',
      'getServicesByIds',
    ]),

    projects() {
      return this.getAllProjects.map(project =>
        Object.assign(_.pick(project, ['id', 'name']), {
          services: this.getServicesByIds(project.servicesIds),
        })
      );
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
+media-down(md)
  .v-services-index
    .collection-preview__header
      padding: $spacing-2 $spacing-3

    .collection-preview__body
      padding: $spacing-3

+media-up(md)
  .v-services-index
    .collection-preview__header
      padding: $spacing-3 $spacing-4

    .collection-preview__body
      padding: $spacing-4
</style>
